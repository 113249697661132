import React, { useCallback, useEffect, useState, useRef } from "react";
import Layout from "../../components/layout/Layout";
import { Link, useParams } from "react-router-dom";
import { requestHandler } from "../../utils/requestHandler";
import { Order, OrderItems } from "../../interfaces/productInterfaces";
import moment from "moment";
import ProductDetail from "./ProductDetail";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function OrderDetail() {
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState<Order | null>(null);
  const [selectedItem, setSelectedItem] = useState<OrderItems | null>(null);
  const pdfRef = useRef<HTMLDivElement>(null);
  const getOrderDetail = useCallback(() => {
    requestHandler<Order>({
      endpoint: `/api/v1/order/admin/${orderId}`,
      method: "GET",
    })
      .then((res) => {
        setOrderDetail(res.data);
      })
      .catch((err) => {});
  }, [orderId]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  const downloadImage = (base64Image: string) => {
    const link = document.createElement("a");
    link.href = base64Image;
    link.download = "downloaded-image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateOrderStatus = (status: string) => {
    requestHandler({
      endpoint: `/api/v1/order/${orderDetail?.orderId}`,
      method: "PUT",
      body: { bookingStatus: status },
    })
      .then((res) => {
        alert(res.message);
        getOrderDetail();
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDownloadPDF = async () => {
    if (!pdfRef.current) return;

    const canvas = await html2canvas(pdfRef.current);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 210; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save("website_ui.pdf");
  };
  return (
    <Layout>
      <section ref={pdfRef} className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Order detail</h2>
            <p>Details for Order ID: {orderDetail?.orderId}</p>
          </div>
        </div>
        <div className="card">
          <header className="card-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mb-lg-0 mb-15">
                <span>
                  {" "}
                  <i className="material-icons md-calendar_today" />{" "}
                  <b>{moment(orderDetail?.createdAt).format("LLL")}</b>{" "}
                </span>{" "}
                <br />
                <small className="text-muted">
                  Order ID: {orderDetail?.orderId}
                </small>
              </div>
              <div className="col-lg-6 col-md-6 ms-auto text-md-end">
                <select
                  className="form-select d-inline-block mb-lg-0 mr-5 mw-200"
                  value={orderDetail?.bookingStatus}
                  onChange={(e) => updateOrderStatus(e.target.value)}
                >
                  <option>Change status</option>
                  {/* <option value={"Awaiting payment"}>Awaiting payment</option> */}
                  <option value={"Confirmed"}>Confirmed</option>
                  <option value={"Shipped"}>Shipped</option>
                  <option value={"Delivered"}>Delivered</option>
                </select>
                {/* <a className="btn btn-primary" href="#">
                  Save
                </a> */}
                <a
                  onClick={handleDownloadPDF}
                  className="btn btn-secondary print ms-2"
                  href="#"
                >
                  <i className="icon material-icons md-print" />
                </a>
              </div>
            </div>
          </header>
          {/* card-header end// */}
          <div className="card-body">
            <div className="row mb-50 mt-20 order-info-wrap">
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-person" />
                  </span>
                  <div className="text">
                    <h6 className="mb-1">Customer</h6>
                    <p className="mb-1">
                      {orderDetail?.name}
                      <br />
                      {orderDetail?.emailAddress} <br />
                      {orderDetail?.phone}
                    </p>
                    {/* <a href="#">View profile</a> */}
                  </div>
                </article>
              </div>
              {/* col// */}
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-local_shipping" />
                  </span>
                  <div className="text">
                    <h6 className="mb-1">Order info</h6>
                    <p className="mb-1">
                      Shipping: Fargo express <br />
                      Pay method: card <br />
                      Status: new
                    </p>
                    <a href="#">Download info</a>
                  </div>
                </article>
              </div>
              {/* col// */}
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-place" />
                  </span>
                  <div className="text">
                    <h6 className="mb-1">Deliver to</h6>
                    <p className="mb-1">
                      {orderDetail?.Address1}
                      <br />
                      {orderDetail?.Address2}
                      City: {orderDetail?.city} <br />
                      State: {orderDetail?.state} <br />
                      Zip: {orderDetail?.zip} <br />
                      Country: {orderDetail?.country} <br />
                    </p>
                    <a href="#">View profile</a>
                  </div>
                </article>
              </div>
              {/* col// */}
            </div>
            {/* row // */}
            <div className="row">
              <div className="col-lg-7">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>View Detail</th>

                        {/* <th>Color</th> */}
                        {/* <th>Size</th> */}
                        {/* <th>Cutomized Images</th> */}

                        <th className="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetail?.items.map((item, i) => {
                        if (item.cartType === "product")
                          return (
                            <tr key={i} className="order-table">
                              <td>
                                <a className="itemside" href="#">
                                  <div className="left">
                                    <img
                                      src={item.product?.images[0] || ""}
                                      width={40}
                                      height={40}
                                      className="img-xs"
                                      alt="Item"
                                    />
                                  </div>
                                  <div className="info">
                                    {item.product?.name}
                                  </div>
                                </a>
                              </td>
                              <td>${item.product?.price}</td>
                              <td>{item.quantity}</td>
                              {/* <td>
                                {" "}
                                <div
                                  style={{
                                    background: item.color,
                                    height: "25px",
                                    width: "25px",
                                    border: "2px solid black",
                                    borderRadius: "5px",
                                  }}
                                ></div>
                              </td>
                              <td>
                                <i className="icon material-icons md-info size-icon">
                                  <div className="order-size-list">
                                    {item.size?.map((item) => {
                                      return (
                                        <p key={item.size}>
                                          {item.size} : {item.quantity}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </i>
                              </td>
                              <td>
                                <a href="#">view Images</a>
                              </td> */}
                              <td>
                                <button
                                  className="btn btn-xs"
                                  onClick={() => setSelectedItem(item)}
                                >
                                  {" "}
                                  View details
                                </button>
                              </td>
                              <td className="text-end">${item.price}</td>
                            </tr>
                          );
                        else
                          return (
                            <tr key={i}>
                              <td>
                                <a className="itemside" href="#">
                                  <div className="left">
                                    {/* <img
                                      src={item.product?.images[0] || ""}
                                      width={40}
                                      height={40}
                                      className="img-xs"
                                      alt="Item"
                                    /> */}
                                    Coupon Applied :
                                  </div>
                                  <div className="info">
                                    {item.coupon?.code}
                                  </div>
                                </a>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>

                              <td className="text-end">${item.price}</td>
                            </tr>
                          );
                      })}
                      {/* <tr>
                        <td>
                          <a className="itemside" href="#">
                            <div className="left">
                              <img
                                src="assets/imgs/items/2.jpg"
                                width={40}
                                height={40}
                                className="img-xs"
                                alt="Item"
                              />
                            </div>
                            <div className="info">Seeds of Change Organic</div>
                          </a>
                        </td>
                        <td>$7.50</td>
                        <td>2</td>
                        <td className="text-end">$15.00</td>
                      </tr>
                      <tr>
                        <td>
                          <a className="itemside" href="#">
                            <div className="left">
                              <img
                                src="assets/imgs/items/3.jpg"
                                width={40}
                                height={40}
                                className="img-xs"
                                alt="Item"
                              />
                            </div>
                            <div className="info">
                              All Natural Italian-Style
                            </div>
                          </a>
                        </td>
                        <td>$43.50</td>
                        <td>2</td>
                        <td className="text-end">$102.04</td>
                      </tr>
                      <tr>
                        <td>
                          <a className="itemside" href="#">
                            <div className="left">
                              <img
                                src="assets/imgs/items/4.jpg"
                                width={40}
                                height={40}
                                className="img-xs"
                                alt="Item"
                              />
                            </div>
                            <div className="info">
                              Sweet &amp; Salty Kettle Corn
                            </div>
                          </a>
                        </td>
                        <td>$99.00</td>
                        <td>3</td>
                        <td className="text-end">$297.00</td>
                      </tr> */}
                      <tr>
                        <td colSpan={5}>
                          <article className="float-end">
                            <dl className="dlist">
                              <dt>Subtotal:</dt>
                              <dd>${orderDetail?.totalAmount}</dd>
                            </dl>
                            {/* <dl className="dlist">
                              <dt>Shipping cost:</dt>
                              <dd>$10.00</dd>
                            </dl> */}
                            <dl className="dlist">
                              <dt>Grand total:</dt>
                              <dd>
                                <b className="h5">
                                  ${orderDetail?.totalAmount}
                                </b>
                              </dd>
                            </dl>
                            <dl className="dlist">
                              <dt className="text-muted">Status:</dt>
                              <dd>
                                <span className="badge rounded-pill alert-success text-success">
                                  Payment done
                                </span>
                              </dd>
                            </dl>
                          </article>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* table-responsive// */}
              </div>
              {/* col// */}
              <div className="col-lg-1" />
              <div className="col-lg-4">
                <div className="box shadow-sm bg-light">
                  <h6 className="mb-15">Payment info</h6>
                  <p>
                    <img
                      src="assets/imgs/card-brands/2.png"
                      className="border"
                      height={20}
                    />{" "}
                    Master Card **** **** 4768 <br />
                    Business name: Grand Market LLC <br />
                    Phone: +1 (800) 555-154-52
                  </p>
                </div>
                <div className="h-25 pt-4">
                  <div className="mb-3">
                    <label>Notes</label>
                    <textarea
                      className="form-control"
                      name="notes"
                      id="notes"
                      placeholder="Type some note"
                      defaultValue={""}
                    />
                  </div>
                  <button className="btn btn-primary">Save note</button>
                </div>
              </div>
              {/* col// */}
            </div>
          </div>
          {/* card-body end// */}
        </div>
        {/* card end// */}
      </section>
      {/* <ProductDetail/> */}
      {selectedItem && (
        <div
          className="modal  bd-example-modal-lg"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content p-5">
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setSelectedItem(null)}
              >
                <i className="icon material-icons md-close" />
              </div>
              {selectedItem?.printingMethod && (
                <div>
                  Printing Method: {selectedItem?.printingMethod.methodName}
                </div>
              )}
              {selectedItem?.size && selectedItem.size.length > 0 && (
                <div>
                  Size:{" "}
                  {selectedItem?.size.map((size) => {
                    return (
                      <span key={size.size}>
                        ({size.size.toUpperCase()} x {size.quantity}){" "}
                      </span>
                    );
                  })}
                </div>
              )}

              {selectedItem?.color && <div>Color: {selectedItem.color}</div>}

              {selectedItem?.customizedSides &&
                selectedItem?.customizedSides.length > 0 && (
                  <div>
                    <span className="font-bold">Cutomized sides</span>
                    <div>
                      {selectedItem.customizedSides.map((item) => {
                        return (
                          <div key={item.side}>
                            <span>
                              {item.side}:{" "}
                              <button
                                className="btn btn-xs ml-2"
                                onClick={() =>
                                  downloadImage(item.customizedImage)
                                }
                              >
                                Download
                              </button>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default OrderDetail;
