import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Products from "./pages/products";
import Categories from "./pages/categories";
import ThemerchlistProducts from "./pages/scripts/ThemerchlistProducts";
import ProductForm from "./pages/products/ProductForm";
import Login from "./pages/auth/Login";
import ProtectedRoute from "./ProtectedRoutes";
import { useAuth } from "./context/AuthProvider";
import Loader from "./components/elements/Loader";
import Users from "./pages/users";
import Banners from "./pages/banners";
import Designs from "./pages/designs";
import Coupons from "./pages/coupons";
import Reviews from "./pages/reviews";
import Headline from "./pages/headline";
import PrintingMethod from "./pages/printingMethods";
import Materials from "./pages/materials";
import PaymentModule from "./pages/paymentModules";
import Orders from "./pages/orders";
import OrderDetail from "./pages/orders/OrderDetail";
import DesignCategory from "./pages/deisgnCategory";
import TopSellingProducts from "./pages/products/TopSellingProducts";
import TrendingProducts from "./pages/products/TrendingProducts";
import Blogs from "./pages/blogs";
import Deals from "./pages/deals";
import AboutUs from "./pages/aboutus";
import PrivacyPolicy from "./pages/privacypolicy";
import ShopDetails from "./pages/shopdetails";
import Shipping from "./pages/shipping";
import InkedJoyProducts from "./pages/scripts/InkedJoyProducts";
import UlianwearProducts from "./pages/scripts/UlianwearProducts";
import Testimonial from "./pages/testimonials";
import Cart from "./pages/cart";

function App() {
  const { token } = useAuth();

  if (token === undefined) return <Loader />;
  return (
    <Routes>
      <Route element={<ProtectedRoute isAuthenticated={Boolean(token)} />}>
        <Route path="/" element={<Dashboard />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}

        <Route path="/products" element={<Products />} />
        <Route
          path="/products/top-selling-products"
          element={<TopSellingProducts />}
        />
        <Route
          path="/products/trending-products"
          element={<TrendingProducts />}
        />

        <Route path="/users" element={<Users />} />
        <Route path="/banners" element={<Banners />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/headline" element={<Headline />} />

        <Route path="/designs" element={<Designs />} />
        <Route path="/deisgn-category" element={<DesignCategory />} />
        <Route path="/printing-methods" element={<PrintingMethod />} />
        <Route path="/payment-module" element={<PaymentModule />} />
        <Route path="/materials" element={<Materials />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/order/:orderId" element={<OrderDetail />} />
        <Route path="/deals" element={<Deals />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/shop-details" element={<ShopDetails />} />
        <Route path="/shipping-charge" element={<Shipping />} />

        <Route
          path="/themerchlist-products"
          element={<ThemerchlistProducts />}
        />
        <Route path="/inkedjoy-products" element={<InkedJoyProducts />} />
        <Route path="/ulianwear-products" element={<UlianwearProducts />} />

        <Route path="/update-product/:id" element={<ProductForm />} />
        <Route path="/create-product" element={<ProductForm />} />
      </Route>

      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
