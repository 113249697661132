import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
// import { type } from "os";
import { requestHandler } from "../../utils/requestHandler";
import {
  CartResponse,
  // Order,
  // OrderResponse,
} from "../../interfaces/productInterfaces";
/* import { dateFormat } from "../../utils/convert";
import Pagination from "../../components/elements/Pagination";
import { Link } from "react-router-dom";
import { number, string } from "yup"; */

function Cart() {
  const [cart, setCart] = useState<CartResponse>({
    currency: 0,
    items: [],
    totalPrice: 0,
  });

  // const [currentPage, setCurrentPage] = useState<number>(1);

  const getAllCarts = useCallback(() => {
    requestHandler<CartResponse>({
      endpoint: `/api/v1/user/cart/admin`,
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          setCart(res.data);
          setCart({
            currency: res?.data?.currency,
            items: res?.data?.items,
            totalPrice: +res?.data?.totalPrice,
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  /*   const handlePageChange = (page: number) => {
    setCurrentPage(page);
  }; */

  useEffect(() => {
    getAllCarts();
  }, [getAllCarts]);
  return (
    <Layout>
      <div className="card mb-4">
        <header className="card-header">
          <h4 className="card-title">All Cart items</h4>
        </header>
        <div className="card-body">
          <div className="table-responsive">
            <div className="table-responsive">
              <table className="table align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col" className="text-center">
                      <div className="form-check align-middle">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="transactionCheck01"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="transactionCheck01"
                        />
                      </div>
                    </th>
                    <th className="align-middle" scope="col">
                      Product
                    </th>
                    <th className="align-middle" scope="col">
                      user name
                    </th>
                    <th className="align-middle" scope="col">
                      quantity
                    </th>
                    <th className="align-middle" scope="col">
                      price
                    </th>
                    <th className="align-middle" scope="col">
                      color
                    </th>
                    <th className="align-middle" scope="col">
                      payment type
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cart?.items?.map((item) => {
                    return (
                      <tr key={item?._id}>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="transactionCheck02"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="transactionCheck02"
                            />
                          </div>
                        </td>
                        <td>
                          <p className="fw-bold">
                            {item?.product?.name ?? "not found"}
                          </p>
                        </td>
                        <td>
                          <p className="fw-bold">
                            {item?.userName ?? "not found"}
                          </p>
                        </td>
                        <td>
                          <p className="inline-block ml-5 ">
                            {" "}
                            {item?.quantity ?? 0}
                          </p>
                        </td>
                        <td>${item?.price}</td>
                        <td>
                          <p className="badge badge-pill badge-soft-success">
                            {item?.color ?? "not found"}
                          </p>
                        </td>
                        <td>
                          <i className="material-icons md-payment font-xxl text-muted mr-5" />{" "}
                          Mastercard
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* table-responsive end// */}
        </div>
      </div>
      {/*  <Pagination
        currentPage={currentPage}
        totalPages={carts.totalPages}
        onPageChange={handlePageChange}
      /> */}
    </Layout>
  );
}

export default Cart;
