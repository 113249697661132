import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useFormik } from "formik";
import { requestHandler, uploadFileHandler } from "../../utils/requestHandler";
import Image from "../../components/elements/Image";
import { IMG_BASE_URL } from "../../utils/constants";
import { TestimonialI } from "../../interfaces/productInterfaces";
function Testimonial() {
  const [testimonials, settestimonials] = useState<TestimonialI[]>([]);
  const [edit, setEdit] = useState({
    editEnable: false,
    editId: "",
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      imageUrl: "",
      role: "",
    },
    onSubmit: (values, { resetForm }) => {
      if (!edit.editEnable) {
        requestHandler({
          endpoint: "/api/v1/testimonial",
          method: "POST",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllTestimonials();
              alert(res.message);
              resetForm();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        requestHandler({
          endpoint: `/api/v1/testimonial/${edit.editId}`,
          method: "PUT",
          body: {
            ...values,
          },
        })
          .then((res) => {
            if (res.success) {
              getAllTestimonials();
              alert(res.message);
              resetForm();
              setEdit({ editEnable: false, editId: "" });
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    },
  });

  const deleteImage = () => {
    formik.setFieldValue("imageUrl", "");
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("path", "testimonials/images");

      uploadFileHandler<string>({
        endpoint: "/api/v1/upload",
        body: data,
      })
        .then((response) => {
          if (response.data)
            formik.setFieldValue(
              "imageUrl",
              `${IMG_BASE_URL}/${response.data}`
            );
        })
        .catch((err) => {
          alert(err.message || "Error uploading in image try again!!");
        });

      // console.log(response.data);
    }
  };

  const handleDelete = async (id: string) => {
    requestHandler({
      endpoint: `/api/v1/testimonial/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          getAllTestimonials();
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const getAllTestimonials = useCallback(async () => {
    requestHandler<TestimonialI[]>({
      endpoint: "/api/v1/testimonial",
      method: "GET",
    })
      .then((res) => {
        if (res.success) {
          settestimonials(res.data);
        }
      })
      .catch((err) => {
        // alert(err.message);
      });
  }, []);

  useEffect(() => {
    getAllTestimonials();
  }, [getAllTestimonials]);
  return (
    <Layout>
      <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Testimonials</h2>
            <p>Add, edit or delete a testimonials</p>
          </div>
          {/* <div>
          <input
            type="text"
            placeholder="Search Categories"
            className="form-control bg-white"
          />
        </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="categoryName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>{" "}
                  <div className="mb-4">
                    <label htmlFor="categoryName" className="form-label">
                      Role
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="role"
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      placeholder="Type here"
                      className="form-control"
                      id="description"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="image" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      placeholder="Type here"
                      className="form-control"
                      id="image"
                      accept="image/*"
                      onChange={onFileChange}
                    />
                  </div>
                  {formik.values.imageUrl && (
                    <Image
                      src={formik.values.imageUrl}
                      onDelete={deleteImage}
                    />
                  )}
                  <div className="d-grid text-center">
                    <button type="submit" className="btn btn-primary">
                      {edit.editEnable
                        ? "Update testimonial"
                        : "Create testimonial"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-9">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                            />
                          </div>
                        </th>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Role</th>
                        <th>Image</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testimonials.map((testimonial, i) => {
                        return (
                          <tr key={testimonial._id}>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                />
                              </div>
                            </td>
                            <td>{i + 1}</td>
                            <td>{testimonial.name}</td>
                            <td>{testimonial.description}</td>
                            <td>{testimonial.role}</td>
                            <td>
                              <Image
                                deleteShow={false}
                                src={testimonial.imageUrl}
                              />
                            </td>

                            <td className="text-end">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  className="btn btn-light rounded btn-sm font-sm"
                                >
                                  {" "}
                                  <i className="material-icons md-more_horiz" />{" "}
                                </a>
                                <div className="dropdown-menu">
                                  {/* <div
                                  className="dropdown-item"
                                  onClick={() =>
                                    viewSubCategory(category.slug)
                                  }
                                >
                                  View/Add Subcategories
                                </div> */}
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "title",
                                        testimonial.name
                                      );
                                      formik.setFieldValue(
                                        "imageUrl",
                                        testimonial.imageUrl
                                      );
                                      formik.setFieldValue(
                                        "description",
                                        testimonial.description
                                      );
                                      setEdit((prev) => ({
                                        ...prev,
                                        editEnable: true,
                                        editId: testimonial._id,
                                      }));
                                    }}
                                  >
                                    Edit info
                                  </div>
                                  <div
                                    className="dropdown-item text-danger"
                                    onClick={() =>
                                      handleDelete(testimonial._id)
                                    }
                                  >
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* .col// */}
            </div>
            {/* .row // */}
          </div>
          {/* card body .// */}
        </div>
        {/* card .// */}
      </section>
    </Layout>
  );
}

export default Testimonial;
