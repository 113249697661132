import React from "react";
import SidebarList from "../elements/SidebarList";
import { SidebarMenu } from "../../interfaces/elementInterfaces";

function Sidebar() {
  const sidebardList: SidebarMenu[] = [
    {
      title: "Dashboard",
      link: "/",
      icon: "icon material-icons md-home",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Orders",
      link: "/orders",
      icon: "icon material-icons md-home",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Categories",
      link: "/categories",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Testimonial",
      link: "/testimonial",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Cart",
      link: "/cart",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Products",
      link: "/products",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: true,
      children: [
        {
          title: "All Products",
          link: "/products",
          icon: "icon material-icons md-shopping_bag",
          hasSubmenu: false,
          children: [],
        },
        {
          title: "Top seliing Products",
          link: "/products/top-selling-products",
          icon: "icon material-icons md-shopping_bag",
          hasSubmenu: false,
          children: [],
        },
        {
          title: "Trending Products",
          link: "/products/trending-products",
          icon: "icon material-icons md-shopping_bag",
          hasSubmenu: false,
          children: [],
        },
      ],
    },
    {
      title: "Deals",
      link: "/deals",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Users",
      link: "/users",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Banners",
      link: "/banners",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Headline",
      link: "/headline",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Designs Category",
      link: "/deisgn-category",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Designs",
      link: "/designs",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Printing Methods",
      link: "/printing-methods",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Payment module",
      link: "/payment-module",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    // {
    //   title: "Materials",
    //   link: "/materials",
    //   icon: "icon material-icons md-shopping_bag",
    //   hasSubmenu: false,
    //   children: [],
    // },
    {
      title: "Coupons",
      link: "/coupons",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Reviews",
      link: "/reviews",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Blogs",
      link: "/blogs",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Aboutus",
      link: "/about-us",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Privacy policy",
      link: "/privacy-policy",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Shop Details",
      link: "/shop-details",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Shipping Charge",
      link: "/shipping-charge",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: false,
      children: [],
    },
    {
      title: "Scripts",
      link: "/themerchlist-products",
      icon: "icon material-icons md-shopping_bag",
      hasSubmenu: true,
      children: [
        {
          title: "Merchlist Products",
          link: "/themerchlist-products",
          icon: "",
          children: [],
          hasSubmenu: false,
        },
        {
          title: "Inkedjoy Products",
          link: "/inkedjoy-products",
          icon: "",
          children: [],
          hasSubmenu: false,
        },
        {
          title: "Ulianwear Products",
          link: "/ulianwear-products",
          icon: "",
          children: [],
          hasSubmenu: false,
        },
      ],
    },
  ];
  return (
    <aside className="navbar-aside" id="offcanvas_aside">
      <div className="aside-top">
        <a href="index.html" className="brand-wrap">
          <img
            src="/assets/imgs/theme/logo.svg"
            className="logo"
            alt="Nest Dashboard"
          />
        </a>
        <div>
          <button className="btn btn-icon btn-aside-minimize">
            <i className="text-muted material-icons md-menu_open" />
          </button>
        </div>
      </div>
      <nav>
        <ul className="menu-aside">
          {sidebardList.map((list, i) => {
            return <SidebarList key={i} list={list} />;
          })}
        </ul>
        <hr />
        {/* <ul className="menu-aside">
          <li className="menu-item has-submenu">
            <a className="menu-link" href="#">
              <i className="icon material-icons md-settings" />
              <span className="text">Settings</span>
            </a>
            <div className="submenu">
              <a href="page-settings-1.html">Setting sample 1</a>
              <a href="page-settings-2.html">Setting sample 2</a>
            </div>
          </li>
          <li className="menu-item">
            <a className="menu-link" href="page-blank.html">
              <i className="icon material-icons md-local_offer" />
              <span className="text"> Starter page </span>
            </a>
          </li>
        </ul> */}
        <br />
        <br />
      </nav>
    </aside>
  );
}

export default Sidebar;
