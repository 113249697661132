// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader {
    z-index: 1000;
    width: 60px;
    aspect-ratio: 1;
    display: flex;
    color: #000;
    border: 4px solid;
    box-sizing: border-box;
    border-radius: 50%;
    background: 
      radial-gradient(circle 5px, currentColor 95%,#0000),
      linear-gradient(currentColor 50%,#0000 0) 50%/4px 60% no-repeat;
    animation: l1 2s infinite linear;
  }
  .loader:before {
    content: "";
    flex: 1 1;
    background:linear-gradient(currentColor 50%,#0000 0) 50%/4px 80% no-repeat;
    animation: inherit;
  }
  @keyframes l1 {
    100% {transform: rotate(1turn)}
  }

  /* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
  
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, 0.8));
  }
  
 `, "",{"version":3,"sources":["webpack://./src/components/elements/loader.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,aAAa;IACb,WAAW;IACX,eAAe;IACf,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB;;qEAEiE;IACjE,gCAAgC;EAClC;EACA;IACE,WAAW;IACX,SAAO;IACP,0EAA0E;IAC1E,kBAAkB;EACpB;EACA;IACE,MAAM,wBAAwB;EAChC;;EAEA,4BAA4B;AAC9B;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,UAAU;IACV,cAAc;IACd,YAAY;IACZ,MAAM;IACN,OAAO;IACP,SAAS;IACT,QAAQ;EACV;;EAEA,wBAAwB;EACxB;IACE,WAAW;IACX,cAAc;IACd,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;MACV,mEAAmE;;IAErE,4EAA4E;EAC9E","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.loader {\n    z-index: 1000;\n    width: 60px;\n    aspect-ratio: 1;\n    display: flex;\n    color: #000;\n    border: 4px solid;\n    box-sizing: border-box;\n    border-radius: 50%;\n    background: \n      radial-gradient(circle 5px, currentColor 95%,#0000),\n      linear-gradient(currentColor 50%,#0000 0) 50%/4px 60% no-repeat;\n    animation: l1 2s infinite linear;\n  }\n  .loader:before {\n    content: \"\";\n    flex: 1;\n    background:linear-gradient(currentColor 50%,#0000 0) 50%/4px 80% no-repeat;\n    animation: inherit;\n  }\n  @keyframes l1 {\n    100% {transform: rotate(1turn)}\n  }\n\n  /* Absolute Center Spinner */\n.loading {\n    position: fixed;\n    z-index: 999;\n    height: 2em;\n    width: 2em;\n    overflow: show;\n    margin: auto;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n  }\n  \n  /* Transparent Overlay */\n  .loading:before {\n    content: '';\n    display: block;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n      background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));\n  \n    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, 0.8));\n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
